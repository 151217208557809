import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import logo from "../../static/images/logo-mikrofon.png"
import iconAppStore from "../../static/images/icon-app-store.svg"

const Header = ({ siteTitle }) => (
  <header>
    <div className={"container"}>
      <div className={"top-menu"}>
        <div className={"logo"}>
          <Link to="/" title={"Kapsel"}>
            <h2>
              {" "}
              <img alt={"Logo"} src={logo} />
              Mikrofon
            </h2>
          </Link>
        </div>

        <div>
          <a
            className={"get-started"}
            href={"https://apps.apple.com/us/app/mikrofon/id1518516143?mt=12"}
            target="_blank"
            rel="noreferrer"
          >
            <img alt={"Available on the App Store"} src={iconAppStore} />
          </a>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
